import React from "react"
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'

import Header from "../components/marketing/Header/Header.jsx"
import Footer from "../components/marketing/Footer/Footer.jsx"
import SEO from "../components/marketing/seo"

import "../scss/pages/About.scss"

import Logo from "!svg-react-loader!../../content/assets/logo.svg?name=Logo";

export const pageQuery = graphql`
  query {
    jessicaHeadshot: file(relativePath: { eq: "headshots/jessica.jpg" }) {
      childImageSharp{
        fluid(maxWidth: 180, quality:80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jakeHeadshot: file(relativePath: { eq: "headshots/jake.jpg" }) {
      childImageSharp{
        fluid(maxWidth: 180, quality:80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

class HomePage extends React.Component {
  render() {
    let jessicaHeadshot = this.props.data.jessicaHeadshot.childImageSharp.fluid
    let jakeHeadshot = this.props.data.jakeHeadshot.childImageSharp.fluid
  
    return (
      <React.Fragment>
        <SEO title={`Learn more about the Hammock mission and team - Hammock`}
             description={`Learn more about the Hammock mission and our team.`}
             keywords={['about', 'freelance', 'freelancers', 'freelance business', 'runway', 'freelance runway', 'freelance forecasting', 'expense tracking', 'expenses', 'freelance taxes', 'freelance quarterly estimated taxes', 'freelance tax' ]}
        />

        <Header />

        <div className="about-page section grey px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-auto">
                <h5 className="section-title">About Us</h5>
                <h1>We're on a mission to empower freelancers.</h1>
                <p>Take a vacation, work on that personal project, and say no to clients who don’t align with your career goals. We're building Hammock to give you the tools you need to improve and grow your freelance business.</p>
                <Link to="/blog/2019/07/07/why-im-building-hammock/">Read more about why we started Hammock.</Link>
              </div>
              <div className="col-md-4 offset-md-2 my-auto pt-5 pt-sm-0">
                <Logo className="about-logo" />
              </div>
            </div>
          </div>
        </div>

        <div className="bios section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 bio-title mb-5">
                <h3>Hammock is a small team, with big ideas.</h3>
                <p>Built for freelancers, by freelancers. We've got your back.</p>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-6 col-sm-4 col-md-2 offset-md-1 mb-3">
                <Img fluid={jessicaHeadshot} alt="Jessica Gillis" className="headshot" />
              </div>
              <div className="col-lg-6 col-md-8">
                <h5 className="name">Jessica Gillis</h5>
                <h6 className="title mb-4">Freelance UX/UI Product Designer &amp; Founder of Hammock</h6>

                <p className="bio-text">Originally from Vancouver Canada, I went to school for design in San Francisco. After exploring the design agency and startup worlds, I moved to Boulder where I discovered my passion for helping small businesses.</p>
                <p className="bio-text">When I'm not freelancing, you can find me hosting crafting events at breweries around town (<a href="https://cutandbaste.com" title="Cut and Baste" target="_blank" rel="noopener noreferrer">cutandbaste.com</a>), and hanging out in the mountains with my husband, Jake, and pup <a href="https://instagram.com/goldenqueso" title="Our pup Queso" target="_blank" rel="noopener noreferrer">@goldenqueso</a>.</p>

                <ul className="links">
                  <li>
                    <a href="mailto:jessica@tryhammock.com?subject=Hi Friend!" target="_blank" rel="noopener noreferrer">Email</a>
                  </li>

                  <li>
                    <a href="https://www.linkedin.com/in/jessicagillis1/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                  </li>
                  <li>
                    <a href="https://www.jessicagillis.com/" target="_blank" rel="noopener noreferrer">Portfolio</a>
                  </li>
                  <li>
                    <a href="https://twitter.com/jessicagillis5" target="_blank" rel="noopener noreferrer">Twitter</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-sm-4 col-md-2 offset-md-1 mb-3">
                <Img fluid={jakeHeadshot} alt="Jake Dahn" className="headshot" />
              </div>
              <div className="col-lg-6 col-md-8">
                <h5 className="name">Jake Dahn</h5>
                <h6 className="title mb-4">Freelance Software Engineer &amp; Founder of Hammock</h6>

                <p className="bio-text"><span role="img" aria-label="Waving Hand">👋</span> Hi Friends!</p>
                <p className="bio-text">I'm a designer, turned software engineer, turned devops engineer, turned information security engineer, turned generalist freelancer. I like to wear all of the hats, and have the most fun when I'm creating value.</p>
                <p className="bio-text">When I'm not freelancing, you can find me working on random side projects, learning to be an artist, or playing video games.</p>

                <ul className="links">
                  <li>
                    <a href="mailto:jake@tryhammock.com?subject=Hi Friend!" target="_blank" rel="noopener noreferrer">Email</a>
                  </li>

                  <li>
                    <a href="https://www.linkedin.com/in/jakedahn/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                  </li>
                  <li>
                    <a href="https://twitter.com/jakedahn" target="_blank" rel="noopener noreferrer">Twitter</a>
                  </li>
                </ul>
              </div>
            </div>


          </div>
        </div>


        <Footer />
      </React.Fragment>
    )
  }
}

export default HomePage
